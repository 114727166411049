/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andrisam
==============================================================================================*/
@import "sass-lib";
@media all and(max-width: 1800px) {
	
	.comingsoon {
		.container {padding: 0 137px;}
		.cm-mid {
			padding: 100px 0 120px 0;
			.title {font-size: 30px; line-height: 50px;}
			p {padding: 0 0; font-size: 15px; line-height: 22px;}     
		}
	}	
}


@media all and(max-width: 1599px) {
	.wrapper, .container {width: 1170px;}

	.btn {
		&.more {
			font-size: 14px; padding: 0 0 15px 0;   
			&:after {
				height:2px; 
			}
			&.white { 
				font-size: 14px; padding: 0 0 15px 0;    
				&:after {
					height:2px; 
				}
			}
		}
	}

	.side-nav {	
		.fl {
			padding: 0 70px 0 100px;
			.inn {padding: 70PX 70px 70px 100px;}
			.as-top {margin-bottom: 50px;} 
			.as-nav {
				ul {
					width: 255px; 
					li {
						a {
							padding: 15px 32px 15px 0; font-size: 22px;  
						}
						ul {
							&:before {top:12px;}
							li {
								a {font-size: 15px; padding: 7px 0;}
							}
						}
					}
				}
			}
		}
		.fr {width: 70px; right: -70px;} 
		.burger-nav {margin: 40px 0 0 22px;} 
		.txt-scroll {
			bottom: 70px; font-size: 11px;
			span {
				padding: 0 0 0 55px; 
				&:before {
					width:40px; 
				}
			}
		}
	}
	header, footer, .middle {padding-left:70px;}      

	header {
		padding-top: 19px; 
		.bgwhite {height: 80px;} 
		.fr {margin-right: 0;}
	}
	.middle {
		.banner {
			.caption {
				padding: 0 100px;
				.title {font-size: 55px; line-height: 65px;}   
				.des {line-height: 25px; font-size: 15px;} 
			}
			&.v2 {
				.img {
					min-height: 450px; max-height: 500px;
					img {object-fit: cover; height: 100%; width: auto; position: relative;}  
				} 
				.caption {
					.small {margin-bottom: 10px;}  
					.title {font-size: 50px; line-height: 60px; padding: 0 180px;}  
				}
			}			
			.slick-initialized {
				.slick-dots {
					padding: 0 100px; bottom: 40px;
					li {
						width: 40px; height: 40px;
						&.slick-active {
							button {width: 25px; height: 25px; font-size: 11px;}   
						}
					}
				}
			}
		}
		.colbox {
			h2 {margin-bottom: 15px;}  
			h3 {
				font-size: 32px; line-height: 43px; margin-bottom: 35px;  
				br {display: none;} 
			} 
			.des {padding: 0; font-size: 15px; line-height: 26px; margin-bottom: 30px;}   
		}
		.home-about {
			.container {padding: 140px 0;}  
			.col {
				&.img {padding: 0 30px 0 0; width: 43%;} 
				&.text {padding: 0; width: 57%;} 
			}
		}
		.box-contact {
			height: 700px; 
			.cl {
				width: 48%;
				&.text {
					padding: 50px 50px 0 0; 
					p {font-size: 15px;} 
				}  
				&.form {width: 52%;} 
			}
			&.page-contact {
				.container {width: 1170px;} 
				.row {
					padding-top: 20px;
					&:before {left:40%;} 
				}
				.nav-tab {
					ul {
						li {
							margin-bottom:0;  
							a {
								display: inline-block; padding: 0 13px;  
							}
						} 
					}
				}
				.cl {
					&.text {width: 40%; padding-top: 0;}
					&.form {width: 55%; padding-left: 5%;} 
				}
				.map {height: 400px;} 
				
			}
		}
		
		.col-corp {
			.container {padding: 0;}   
			.fl {
				.col {height: 150px;}
			}
			.fr {
				.col {height: 75px;}  
			}
		}

		.midcontent {
			.container {padding: 60px 0;} 
			.inner {padding: 60px 80px 20px 80px;}  
			&.v3,
			&.v4 {
				.container {width: 1170px;} 
			}
			h5 {font-size: 21px;} 

			.box-img {
				padding: 20px 0 0 0; 
				&.play {
					height: 385px;
					.img {						
						&:after {width:70px; height: 70px; background-size: 100%;}    
					}
					.video {
						padding-top: 20px;
						iframe {}   
					}  
				}
			} 
			.box-vimis {  
				padding: 50px 0;
				.row {
					 .col {
					 	&.vtitle {width: 30.2%;} 
					 	&.vdes {width: 69.8%;} 
					 }
				}
			} 

		}
		
		.list-row {
			&.v2 {
				.container {width: 1170px; padding: 70px 0;}   
				.col {
					&.img {
						width: 50.17%;
						img {margin-top: 0;} 
					}
					&.text {
						width: 49.83%; padding-left: 30px; 
						.position {font-size: 20px;}
					}  
				}
			}
			&.v3 {
				.col {
					&.img {
						width: 50.17%;
						img {margin-top: 0;} 
					}
					&.text {
						width: 49.83%; padding-left: 30px; 
						.position {font-size: 20px;}
					}  
				}
			}
			&.business {
				.row {
					.col {
						&.text {padding-top: 30px; padding-right: 0;}        
					}
					&:nth-child(even) {
	                    .col {
	                        &.img {} 
	                        &.text {padding-left: 0; padding-right: 60px;}           
	                    }
	                }
				}
			}
		}

		.list-manag {
			.col {
				.img {padding: 0 0; margin:0 0 30px 0; width: 90%;}       
				.name {font-size: 30px; line-height: 40px;} 
				.position {font-size: 20px; line-height: 25px; margin-bottom: 25px;}    
			}
		}
		.col-achievement {  
			.col {
				width: 28.3%; margin: 0 5% 5% 0; height: 310px;  
				&:nth-child(4n+4) {margin-right: 5%;} 
				&:nth-child(3n+3) {margin-right: 0;}  
			} 
		}
	
		.filtering {
			padding: 0 0;  
		}
		.our-product { 
			padding: 0 0;
			.row {
				padding: 55px 0 55px 0;  
				h3 {font-size: 33px; margin-bottom: 30px;}    
				.box {
					.col {
						&.text {
							padding: 0 0 0 30px; 
							p {font-size: 15px; line-height: 25px;}    
						}
						&.gallery {
							.thumbSlider { 
								width: 100%;
								.thumb {width: 70px; height: 70px; margin: 0 5px 5px 0;}  
								/*.slick-track {width: 100% !important;}  */
							}
						}
					}
				}
				&:last-child {
					border-bottom: 0; 
				}
			}
		}

		.form-std {
			label {margin-bottom: 10px;}
			input {font-size: 15px; height: 40px; line-height: 40px;}  
			textarea {font-size: 15px; line-height: 17px;} 
			.col {
				margin-bottom: 25px;
			}
		}

		

		


	}

	.content-404 {min-height: 400px;} 

	footer { 
		padding-top: 25px; padding-bottom: 25px;  
		.logo-foot {width: 215px;}   
		.nav-foot {
			margin-bottom: 9px;  
			ul {
				li {
					a {font-size: 11px;}   
				}
			}
		}
		.copyright {font-size: 11px;}   
	}

	#boxPop {
		min-width: 860px; max-width: 860px;    
	}

}